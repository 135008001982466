* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Lato", sans-serif;
  background-color: black;
  color: white;
  line-height: 1.6;
}
#author {
  font-size: 12px;
  color: white;
}

#stats {
  text-align: center;
  line-height: 1.5rem;
}

.highlight {
  font-size: 14px;
  color: yellow;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

header {
  background: black;
  padding: 20px;
  text-align: center;
}

nav ul {
  list-style: none;
  padding: 0;
  text-align: center;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}

nav ul li a {
  text-decoration: none;
  color: grey;
}

.main {
  padding: 20px;
  text-align: center;
}

.main canvas {
  max-width: 100%;
  height: auto;
}

footer {
  /* background: black; */
  padding: 20px;
  text-align: center;
}

#output {
  text-align: center;
}

#app {
  display: flex;
  justify-content: center;
  align-items: center;
}

.artCanvasContainer {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.canvas-container {
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.generated-art {
  max-width: 100%;
  height: auto;
  display: block;
}
